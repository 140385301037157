<template>
  <b-modal id="addclassmodal" size="md" class="addclassmodal" modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2  v-if="event && canBeEdited">{{ $t('edit_class') }}</h2>
      <h2  v-if="event && !canBeEdited">{{ $t('lesson') }}</h2>
      <h2  v-if="!event">{{ $t('new_schedule') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <div>
      <b-row v-for="(t, tind) in times" class="position-relative" :key="'time-row-'+tind">
        <b-col>
          <div class="select-wrapper form-group">
            <b-form-select class="form-control form-control-white"
                           :disabled="!canBeEdited"
                           v-model="t.day">
              <option v-for="(option, idx) in daysdurations"
                      :key="'daydur-'+idx"
                      :value="stringFormat(option)">
                {{ option | moment('DD MMM, ddd') }}
              </option>
            </b-form-select>
          </div>
        </b-col>
        <b-col cols="auto">
          <div class="select-wrapper form-group">
            <b-form-select class="form-control form-control-white"
                           :disabled="!canBeEdited"
                           v-model="t.time">
              <option v-for="(option, idx) in timedurations"
                      :key="'timedur-'+idx"
                      :value="$options.filters.timeFromSeconds(option)">
                {{ option | timeFromSeconds }}
              </option>
            </b-form-select>
          </div>
        </b-col>
        <b-col cols="auto">
          <div class="select-wrapper form-group">
            <b-form-select class="form-control form-control-white" :disabled="!canBeEdited" v-model="t.duration">
              <option v-for="(option, idx) in seldurations"
                      :key="'seldur-'+idx"
                      :value="option">
                {{ option }} {{ $t('min') }}
              </option>
            </b-form-select>
          </div>
          <Close2Svg class="remove-time pointer" v-if="tind > 0" @click="removeTime(tind)"/>
        </b-col>
      </b-row>
      <a class="btn-link add-time-btn d-block" v-if="!event" @click="addTime">+ {{ $t('add_another_lesson_time') }}</a>
    </div>
    <div>
      <h3>{{ $t('program') }}</h3>
      <div class="form-group">
        <a class="btn-whole-add btn-whole-add-dashed" v-if="!material" @click="selectProgram">
          <span class="svg-wrapper mr-3"><PlusSvg class="svg-icon-sm"/></span>{{ $t('add_program') }}
        </a>
        <div class="selected-material" v-if="material">
          <b-row class="align-items-center">
            <b-col>
              <div v-if="material.course" class="text-muted small">{{ material.course.title }}</div>
              <div>{{ material.title }}</div>
            </b-col>
            <b-col cols="auto">
              <a v-if="canBeEdited" class="btn-themed btn-white py-2" @click="selectProgram">{{ $t('edit') }}</a>
              <a class="btn-themed btn-white py-2 ml-2" @click="preview">{{ $t('preview') }}</a>
              <!--                        <div><PenSvg class="edit-btn pointer" @click="selectProgram"/></div>-->
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div>
      <h3>{{ $t('add_student') }}</h3>
      <StudentSearch @selected="studentsSelected"
                     :dropdown="true"
                     :onlyactive="true"
                     :teacher="teacher"
                     :editable="canBeEdited"
                     :passedstudents="passedgroups.length > 0 ? [] : passedstudents"
                     :passedgroups="passedgroups"
                     :showgroups="true"
      />
    </div>
    <div class="row">
      <div class="col-md-6">
        <h3>{{ $t('methodics') }}</h3>
        <SelectGroup class="default"
                     v-model="methodics"
                     :translate="true"
                     :options="methodicsOptions"/>
      </div>
      <div class="col-md-6">
        <h3>{{ $t('streaming_type') }}</h3>
        <SelectGroup class="default"
                     v-model="streaming_type"
                     :translate="false"
                     :options="streamingTypes"/>
      </div>
    </div>

    <CheckboxGroup class="mt-3"
                   :label="$t('repeat_weekly')"
                   v-model="repeating"
                   fid="repeatinglesson"/>

    <CheckboxGroup class="mt-3"
                   :label="$t('opened_lesson')"
                   v-model="opened"
                   fid="opened"/>

    <div class="mt-5" v-if="id">
      <a class="pointer btn-themed btn-white py-2 w-100 font-weight-light"
         @click="copyUnauthorizedLink">
        <LinkSvg style="width: 20px;" class="svg-gray mr-2"/>
        {{ $t('copy_unauthorized_link') }}
      </a>
    </div>

    <template v-slot:modal-footer>
      <div>
        <b-button v-if="id"
                  class="btn-themed btn-white ml-2"
                  :disabled="loading"
                  @click="destroy">
          <TrashSvg/>
        </b-button>
        <b-button v-if="canBeEdited"
                  class="btn-themed btn-white ml-2"
                  :disabled="loading"
                  @click="save">
          {{ $t('save') }}
        </b-button>
        <b-button
            v-if="!id && !event && !creatingForOther && material"
            class="btn-themed ml-2"
            :disabled="loading"
            @click="saveStart">
          {{ $t('save_and_start')}}
        </b-button>
        <b-button v-if="id && event && event.material_id && event.instructor_id == $store.state.user.id"
                  class="btn-themed ml-2"
                  @click="startSession(id)">{{ $t('start') }}
        </b-button>
      </div>
    </template>
    <SelectMaterial @selected="materialSelected"/>
    <PresentationPreviewModal v-if="showmodal"
                              :material="material"
                              :homework="false"/>
  </b-modal>
</template>

<script>
import PlusSvg from '@/assets/svg/plus.svg'
import CloseSvg from '@/assets/svg/close.svg'
import Close2Svg from '@/assets/svg/close2.svg'
import TrashSvg from '@/assets/svg/trash2.svg'
import LinkSvg from '@/assets/svg/link.svg'
import StudentSearch from '@/views/parts/class/StudentSearch'
import SelectMaterial from '@/views/parts/class/SelectMaterial'
import CheckboxGroup from "@/views/parts/general/form/CheckboxGroup"
import PresentationPreviewModal from '@/views/parts/class/preview/PresentationPreviewModal'
import SelectGroup from "@/views/parts/general/form/SelectGroup"
import { LessonsService, PricePoliciesService } from '@/services/api.service'
import {mapGetters} from "vuex"
const moment = require('moment')

export default {
  components: {
    CloseSvg,
    Close2Svg,
    PlusSvg,
    StudentSearch,
    TrashSvg,
    LinkSvg,
    SelectMaterial,
    PresentationPreviewModal,
    CheckboxGroup,
    SelectGroup
  },
  data() {
    return {
      id: null,
      material: null,
      prices: [],
      times: [{}],
      lesson: null,
      repeating: false,
      opened: false,
      showStart: false,
      students: [],
      group: null,
      methodics: 'standard',
      streaming_type: null,
      passedstudents: [],
      passedgroups: [],
      daysdurations: this.enumerateDaysBetweenDates(moment().format('YYYY-MM-DD'), moment().add(10,'days').format('YYYY-MM-DD')),
      seldurations: this.getListNumbers(30, 180, 15),
      timedurations: this.getListNumbers(0, (24 * 60 * 60) - (15 * 60), 15 * 60),
      showmodal: false,
      loading: false,
      methodicsOptions: [
        { id: 'standard', title: 'standard'},
        { id: 'explication', title: 'explication'},
      ],
      streamingTypes: [
        { id: 'webrtc', title: 'P2P'},
        { id: 'edumeet', title: 'SFU'},
        // { id: 'jaas', title: 'JAAS'},
      ]

    }
  },
  props: {
    giventime: {
      type: Object
    },
    event: {
      type: Object
    },
    teacher: {
      default: null
    }
  },
  methods: {
    addTime() {
      if(this.times.length > 0) {
        this.times.push(JSON.parse(JSON.stringify(this.times.slice(-1).pop())))
      } else {
        this.times.push(this.getCurrentTime())
      }
    },
    removeTime(index) {
      this.times.splice(index, 1)
    },
    getCurrentTime() {
      return {
        day: moment().tz(this.$store.state.user.timezone).format('YYYY-MM-DD'),
        time: '15:00',
        duration: 60,
      }
    },
    stringFormat(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    hourFormat(date) {
      return moment(date).format('HH:mm')
    },
    studentsSelected(data, type = 'students') {
      if(type == 'students') {
        this.students = data.map(a => a.id)
        this.group = null
      }
      if(type == 'groups') {
        this.group = data.length > 0 ? data[0].id : null
        this.students = []
      }
    },
    save() {
      this.loading = true
      if(this.id) {
        let time = this.times[0]
        let params = {
          day: time.day,
          time: time.time,
          duration: time.duration,
          students: this.students,
          group_id: this.group,
          repeating: this.repeating,
          opened: this.opened,
          instructor_id: this.teacher,
          material_id: this.material ? this.material.id : null,
          methodics: this.methodics,
          streaming_type: this.streaming_type
        }
        LessonsService.update(this.id, params).then(res => {
          let r = this.apiResponse(res)
          if(r.success) {
            this.$bvModal.hide('addclassmodal')
            this.$toast(this, this.$t('class_updated'))
          } else {
            this.$error(r.data)
          }
          this.loading = false
        }).catch(() => this.loading = false)
      } else {
        LessonsService.add({
          times: this.times,
          students: this.students,
          group_id: this.group,
          instructor_id: this.teacher,
          repeating: this.repeating,
          opened: this.opened,
          methodics: this.methodics,
          streaming_type: this.streaming_type,
          material_id: this.material ? this.material.id : null,
        }).then(res => {
          let r = this.apiResponse(res)
          if(r.success) {
            this.$bvModal.hide('addclassmodal')
            this.$success(this.$t('class_added'))
          } else {
            this.$error(r.data)
          }
          this.loading = false
        }).catch(() => this.loading = false)
      }
    },
    saveStart() {
      let options = {}
      this.loading = true
      LessonsService.add({
        times: this.times,
        students: this.students,
        group_id: this.group,
        instructor_id: this.teacher,
        repeating: this.repeating,
        opened: this.opened,
        methodics: this.methodics,
        streaming_type: this.streaming_type,
        material_id: this.material ? this.material.id : null,
      }).then(res => {
        this.loading = false
        res.data.data.forEach(x => {
          options.id = x.id
          options.code = x.code
          options.material = x.material_id
          return options
        })
        if(!this.material) {
          this.$error(this.$t('lesson_has_no_program'))
          return
        }
        // if(!(this.students && this.students.length) && !this.group) {
        //   this.$error(this.$t('first_you_need_to_add_students'))
        //   return
        // }
        this.$router.push({
          name: 'Lesson',
          params: {
            id: options.id, code: options.code
          }
        })
      }).catch(() => this.loading = false)
    },
    destroy() {
      if(!this.id) return
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
        this.loading = true
        LessonsService.delete(this.id).then(() => {
          this.loading = false
          this.$bvModal.hide('addclassmodal')
          this.$success(this.$t('class_removed'))
          this.$emit('refresh')
        }).catch(() => this.loading = false)
      })
    },
    startSession(id) {
      if(!this.event) {
        this.$error(this.$t('lesson_has_no_program'))
        return
      }
      this.$router.push({
        name: 'Lesson',
        params: {
          id: id,
          code: this.event.code
        }
      })
    },
    selectProgram() {
      this.$bvModal.show('selectmaterialmodal')
    },
    materialSelected(material) {
      this.material = material
    },
    preview() {
      this.showmodal = true
      setTimeout(() => {
        this.$bvModal.show('presentationpreviewmodal')
      },50)
    },
    getPricePolicies() {
      PricePoliciesService.get(false).then(res => {
        this.prices = res.data.data
      })
    },
    async copyUnauthorizedLink() {
      const route = this.$router.resolve({
        name: 'UnauthorizedToLesson',
        params: { id: this.id, code: this.event.code }
      })
      await navigator.clipboard.writeText(`${window.location.origin}${route.href}`)
      this.$success(this.$t('copied'))
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId === "addclassmodal") {
        this.students = []
        if(this.event) {
          this.lesson = this.event
          this.times = [{
            day: this.event.start._i.slice(0,10),
            time: this.event.start._i.slice(11,16),
            duration: this.event.duration,
          }]
          // this.price_policy_id = this.event.price_policy_id
          this.id = this.event.id
          this.repeating = this.event.repeating
          this.opened = this.event.opened
          this.material = this.event.material
          this.methodics = this.event.methodics
          this.streaming_type = this.event.streaming_type
          if(this.event.students) {
            this.passedstudents = this.event.students.map(a => a.id)
          } else {
            this.passedstudents = []
          }
          this.passedgroups = this.event.group_id ? [this.event.group_id] : []
        } else {
          this.lesson = null
          this.id = null
          this.passedstudents = []
          this.passedgroups = []
          this.material = null
          this.methodics = 'explication'
          this.streaming_type = null
          this.repeating = false
          this.opened = false
          this.times = this.giventime ? [this.giventime] : [this.getCurrentTime()]
        }
      }
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === "presentationpreviewmodal") {
        this.showmodal = false
      }
    })
    if(this.hasJaas && !this.streamingTypes.find(x => x.id === 'jaas')) {
      this.streamingTypes.push({ id: 'jaas', title: 'JAAS'})
    }
    this.getPricePolicies()
  },
  computed: {
    canBeEdited() {
      // return true
      return !this.id || (this.id && !this.lesson.attendance_set && !this.lesson.used)
    },
    creatingForOther() {
      return this.teacher && this.teacher != this.$store.state.user.id
    },
    ...mapGetters(['schoolMetadata']),
    hasJaas() {
      return !!this.schoolMetadata.jaasOn
    }
  }

}
</script>

<style scoped lang="scss">
#addclassmodal {
  .remove-time {
    width: 24px;
    height: 24px;
    position: absolute;
    right: -26px;
    top: 14px;
  }
  .add-time-btn {
    margin-bottom: 18px;
  }
  .selected-material {
    background: $almost-white;
    border-radius: 16px;
    padding: 18px 24px;
    font-size: 14px;
    font-weight: 600;
  }
  .edit-btn {
    width: 16px;
    height: 16px;
    fill: $gray-fill;
  }
}
</style>






